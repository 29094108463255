
<style lang="css">
.ivu-drawer-content {
  background-color: #1a1a1a !important;
}
</style>
<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          v-model="assetId"
          size="small"
          :clearable="true"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="站点"
          :clearable="true"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col
        span="3"
        v-if="taskType!==11"
      >
        <Select
          v-model="query.position"
          size="small"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col
        span="3"
        v-if="taskType!==11"
      >
        <Select
          v-model="query.resourcetypeId"
          size="small"
          :clearable="true"
          placeholder="资源类型"
        >
          <OptionGroup
            v-for="item in resourceTypeArray"
            :key="'re_parent_'+item.id"
            :label="item.name"
          >
            <Option
              v-for="child in item.children"
              :value="child.id"
              :key="'re_child'+child.id"
            >{{ child.name }}</Option>
          </OptionGroup>
        </Select>
      </i-col>
      <i-col span="2">
        <Select
          v-model="query.status"
          size="small"
          placeholder="任务状态"
          :clearable="true"
        >
          <Option
            v-for="item in taskStatusArray"
            :key="'ps_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Input
          search
          v-model="query.keyword"
          size="small"
          enter-button="搜索"
          placeholder="资源编号、站点名称"
          @on-search="handleSearch"
        />
      </i-col>
      <i-col span="6">
        <Button
          v-if="[8,9,10].includes(taskType)"
          type="primary"
          size="small"
          class="m-r-5"
          :disabled="selectedTaskStatus!==-2||selectedTaskitemIdArray.length===0"
          @click="handleAddNewTask"
        >编辑任务</Button>
        <Button
          type="error"
          size="small"
          class="m-r-5"
          :disabled="selectedTaskStatus!==-2||selectedTaskitemIdArray.length===0"
          @click="handelDeleteTaskitem"
        >删除设置</Button>
        <Button
          type="error"
          size="small"
          class="m-r-5"
          :disabled="![-3,0,1,2,4].includes(selectedTaskStatus)||selectedTaskitemIdArray.length===0"
          @click="handleCancelTask"
        >撤销任务</Button>
        <Button
          type="success"
          size="small"
          @click="handleSubmitTask"
        >提交全部任务</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        size="small"
        max-height="420"
        :data="tableData"
        :columns="tableColumns"
        @on-selection-change="handleSelection"
      ></Table>
      <Row :gutter="8">
        <i-col span="12">
          <div
            class="m-t-5"
            style="line-height:25px;"
          >当前已选中<span style="color:#ff9900;padding:0 3px">{{selectedTaskitemIdArray.length}}</span>条</div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              :total="total"
              :page-size="query.pageSize"
              size="small"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change='changePageSize'
              :current="query.pageNumber"
              @on-change="changePage"
            ></Page>
          </div>
        </i-col>
      </Row>

    </div>
    <Modal
      v-model="setTaskModal"
      width="800"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >编辑任务</p>
      <component
        v-if="setTaskModal"
        :is="curSetTaskComponent"
        :opreateType="2"
        :taskId="taskId"
        :taskType="taskType"
        :deviceId="deviceId"
        :selectedResourceIdArray="selectedResourceIdArray"
        :selectedTaskitemIdArray="selectedTaskitemIdArray"
        @handleSetSuccess="handleSetSuccess"
      ></component>
    </Modal>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}} {{variableTitle}}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>

    <!-- 测量任务的SVG套搞查看 -->
    <SvgMaintenanceTask ref="SvgMaintenanceTask" />
  </div>
</template>

<script>
// import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import { formatEnable, formartMspTaskitemStatus, formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPositions } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
import { submit, deleteTaskitem } from '@/api/msp/demandV3'
import { applyRevokeTaskitem } from '@/api/msp/terminate'
import { getTaskitemStatus, getTaskItemPage } from '@/api/msp/taskitem'

import ImgLightBox from '@/components/common/ImgLightBox'
import SvgMaintenanceTask from '@/components/svg/SvgMaintenanceTask'

export default {
  mixins: [companyMixin, commonMixins],
  components: {
    ImgLightBox,
    SvgMaintenanceTask,
    SetRepairTask: () => import('../common/SetRepairTask'),
    SetMaintainTask: () => import('../common/SetMaintainTask'),
    SetTempTask: () => import('../common/SetTempTask'),
    SetFillFile: () => import('../common/SetFillFile')
  },
  data () {
    return {
      setTaskModal: false,
      curSetTaskComponent: '',
      companyId: this.$store.getters.token.userInfo.companyId,
      assetId: null,
      curTaskId: null,
      deviceId: null,
      // taskType: null,
      // curTaskStatus: null,
      taskStatusArray: [],
      query: {
        taskId: null,
        assetIds: '',
        stationId: null,
        position: null,
        resourcetypeId: null,
        keyword: '',
        status: null,
        pageSize: 15,
        pageNumber: 1
      },
      tableData: [],
      total: 0,
      pagesizeSetting: [15, 50, 100, 200, 500],
      selectedTaskStatus: null,
      // selectedTaskitemIds: [], // 撤销任务使用
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      taskTypeShowColumns: new Map([
        [5, ['selection', 'code', 'domainName', 'actionDate', 'actionFile', 'repairName', 'supplierName', 'status', 'remark', 'revoke', 'operate']], // 维修
        [8, ['selection', 'domainName', 'actionDate', 'supplierName', 'status', 'remark', 'revoke']], // 巡检
        [7, ['selection', 'code', 'domainName', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'status', 'remark', 'revoke', 'operate']], // 下刊申请
        [9, ['selection', 'code', 'domainName', 'typeName', 'actionDate', 'actionFile', 'supplierName', 'status', 'remark', 'revoke', 'operate']], // 维护
        [10, ['selection', 'code', 'domainName', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'status', 'remark', 'revoke', 'operate']], // 画面填充
        [11, ['selection', 'code', 'assetName', 'domainName', 'actionDate', 'supplierName', 'status', 'remark', 'revoke', 'operate']] // 临时任务
      ]),
      tableColumns: [],
      visiblePreview: false,
      fileName: '',
      variableTitle: '',
      tempSelectedTaskItem: [],
      selectedTaskitemIdArray: [],
      selectedResourceIdArray: []
    }
  },
  computed: {
    taskId () {
      return this.$store.state.equipment.taskId
    },
    taskType () {
      return this.$store.state.equipment.taskType
    },
    taskStatus () {
      return this.$store.state.equipment.taskStatus
    }
  },
  created () {
    // this.setLinkTagArray({ key: 'DemandDetial', value: '任务查看', actived: true })
    // this.setActivedTagName('DemandDetial')

    // this.$store.commit('set_opreate_type', 2) // 编辑操作标识设置

    // this.getCompanyAssetArray()
    // this.getTaskitemData()
    this.getTaskStatusData()
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        code: { title: this.taskType === 11 ? '任务名称' : '编号', key: 'code', minWidth: 100, align: 'center' },
        assetName: { title: '所属资产', key: 'assetName', minWidth: 100, align: 'center' },
        domainName: { title: '所属站台', key: 'domainName', minWidth: 100, align: 'center' },
        typeName: { title: '任务类型', key: 'typeName', minWidth: 100, align: 'center' },
        // finishDate: { title: '当前在刊结束日', key: 'finishDate', minWidth: 100, align: 'center' },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('p', params.row.actionDate),
              h('Tag', {
                props: { color: params.row.actionDateModifyStatus === 1 ? 'default' : 'primary' }
              }, params.row.actionDateModifyStatusName)
            ])
          }
        },
        actionFile: {
          title: '作业画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              if (params.row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFiles
                      }
                      this.sortIndex = 0
                      this.fileName = `${params.row.domainName} ${params.row.code}`
                      this.variableTitle = ''
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFiles.map(x => x.fileKey)
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.domainName} ${params.row.code}`
                          this.variableTitle = ''
                          this.curShowFileArray = params.row.taskitemFiles
                          this.$refs.imgLightBox.init(postData)
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        repairName: {
          title: '维修项',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h('Tooltip', {
              props: {
                content: params.row.taskitemRepair.repairName || '',
                transfer: true,
                'max-width': '200px'
              }
            }, params.row.taskitemRepair.repairName && params.row.taskitemRepair.repairName.length > 12 ? params.row.taskitemRepair.repairName.substr(0, 11) + '...' : params.row.taskitemRepair.repairName)
          }
        },
        actionName: {
          title: '作业名称',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', Array.from(new Set(params.row.taskitemFiles.map(x => x.fileName))))
          }
        },
        picturesource: {
          title: '画面来源',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.picturesourceName),
              params.row.isReuseOldFile ? h('p', {
                style: { color: '#ff9900' }
              }, '使用已有画面') : null
            ])
          }
        },
        supplierName: {
          title: '供应商',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.companyName)
          }
        },
        userName: {
          title: '巡检人员',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.userName)
          }
        },
        status: {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName),
              formartMspTaskitemRephotoStatus(h, params.row.rephoto)
            ])
          }
        },
        remark: { title: '备注', key: 'remark', align: 'center', minWidth: 100, ellipsis: true, tooltip: true },
        revoke: {
          title: '撤销申请',
          minWidth: 80,
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.revocation)
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = []
            if (params.row.type === 6) { // 测量任务
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$refs.SvgMaintenanceTask.initPageData(params.row, true)
                  }
                }
              }, '测量详情'))
            } else {
              if (params.row.taskitemAttachs && params.row.taskitemAttachs.length) {
                html.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemAttachs.map(x => x.fileKey)
                      }
                      this.fileName = `${params.row.domainName} ${params.row.code}`
                      // this.variableTitle = params.row.taskitemAttachs[0].uploadTime ? `（上传时间：${params.row.taskitemAttachs[0].uploadTime}）` : ''
                      this.variableTitle = params.row.finishTime ? `（完成时间：${params.row.finishTime}）` : ''
                      this.curShowFileArray = params.row.taskitemAttachs
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  }
                }, '照片反馈'))
              }
              if (params.row.status === -2) {
                if ([5, 11].includes(this.taskType)) {
                  html.push(h('a', {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.handleEditTask(params.row)
                      }
                    }
                  }, '编辑'))
                }
                if (this.taskType === 5) {
                  html.push(h('a', {
                    style: { marginRight: '5px', color: '#f87676' },
                    on: {
                      click: () => {
                        this.selectedTaskitemIdArray = [params.row.id]
                        this.handelDeleteTaskitem()
                      }
                    }
                  }, '删除'))
                }
              }
            }
            // if (params.row.resourceNotFinishTask.id) {
            //   html.push(h('a', {
            //     style: { display: 'block' },
            //     on: {
            //       click: () => {
            //         this.relatedTaskObj = params.row.resourceNotFinishTask
            //         this.curTaskItemStatus = params.row.status
            //         this.produceTaskitemStatus = params.row.produceTaskitemStatus
            //         this.mergeModel.executeTaskitemId = this.taskType === 1 ? params.row.id : params.row.resourceNotFinishTask.id
            //         this.mergeModel.terminateTaskitemId = this.taskType === 1 ? params.row.resourceNotFinishTask.id : params.row.id
            //         this.taskViewModal = true
            //       }
            //     }
            //   }, '关联任务'))
            // }
            return h('div', html)
          }
        }
      }

      const data = []
      var showColumns = this.taskTypeShowColumns.get(this.taskType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    getTaskStatusData () {
      getTaskitemStatus({ taskType: this.taskType }).then(res => {
        if (res && !res.errcode) {
          this.taskStatusArray = res
        } else {
          this.taskStatusArray = []
        }
      })
    },
    getTaskitemData () {
      this.tableColumns = this.getTableColumns()
      this.query.assetIds = this.assetId ? JSON.stringify([this.assetId]) : ''
      this.query.taskId = this.curTaskId

      getTaskItemPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableData = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
          const svgData = []
          if (this.tableData && this.tableData.length) {
            this.tableData.forEach(item => {
              item._disabled = false
              if (item.status === 3) {
                // 已完成状态不进行统计
                item._disabled = true
                return false
              }
              const exitsData = svgData.find(x => x.stationId === item.domainId)
              if (exitsData) {
                exitsData.totalCount++
              } else {
                svgData.push({
                  assetId: item.assetId,
                  assetName: item.assetName,
                  stationId: item.domainId,
                  stationName: item.domainName,
                  totalCount: 1
                })
              }
            })
          }
          this.$store.commit('set_equipment_mapStations', svgData)
        }
      })
      // this.$store.dispatch('getTaskitemData', this.query)
    },
    handleChangeAsset () {
      this.query.pageNumber = 1
      this.tempSelectedTaskItem = []
      this.selectedTaskitemIdArray = []
      this.selectedResourceIdArray = []
      this.getStationData()
      this.getPositionData()
      this.getResourceTypeData()
      this.getTaskitemData()
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: this.assetId ? JSON.stringify([this.assetId]) : '' }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      const assetIds = []
      assetIds.push(this.assetId)
      const data = {
        assetIds: JSON.stringify(assetIds)
      }
      getPositions(data).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getResourceTypeData () {
      const postData = {
        assetId: this.assetId
      }
      getResourceTypeList(postData).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.tempSelectedTaskItem = []
      this.selectedTaskitemIdArray = []
      this.selectedResourceIdArray = []
      this.getTaskitemData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.tempSelectedTaskItem = []
      this.selectedTaskitemIdArray = []
      this.selectedResourceIdArray = []
      this.getTaskitemData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTaskitemData()
    },
    handleSelection (selection) {
      this.selectedTaskitemIdArray = []
      this.selectedResourceIdArray = []
      var tempSelectedTaskStatus = []

      // 最终选中的所有taskItem
      const curSelection = selection.map(x => {
        return { id: x.id, resourceId: x.resourceId, deviceId: x.deviceId, status: x.status }
      })
      this.handelPageSelections(this.tempSelectedTaskItem, curSelection, this.query.pageNumber)
      this.tempSelectedTaskItem.forEach(item => {
        this.selectedTaskitemIdArray = this.selectedTaskitemIdArray.concat(item.existSelections.map(x => x.id))
        this.selectedResourceIdArray = this.selectedResourceIdArray.concat(item.existSelections.map(x => x.deviceId))
        tempSelectedTaskStatus = tempSelectedTaskStatus.concat(item.existSelections.map(x => x.status))
      })

      const selectedTaskStatusArray = Array.from(new Set(tempSelectedTaskStatus))
      this.selectedTaskStatus = selectedTaskStatusArray[0]

      if (selectedTaskStatusArray.length > 1) {
        this.selectedTaskitemIdArray = []
        this.selectedResourceIdArray = []
        this.selectedTaskStatus = null
        this.$Notice.warning({ desc: '请选择相同状态的任务进行批量操作' })
      }
    },
    /**
     * 单条数据编辑
     */
    handleEditTask (data) {
      // this.$store.commit('set_show_left_block', true)

      this.selectedTaskitemIdArray = [data.id]
      this.setTaskModal = true
      switch (this.taskType) {
        case 5: // 维修任务
          this.deviceId = data.deviceId
          // this.$store.commit('set_left_component', 'SetRepairTask')
          this.$store.commit('set_cur_device_rapair_info', Object.assign(data.taskitemRepair, { actionDate: data.actionDate, remark: data.remark }))
          this.curSetTaskComponent = 'SetRepairTask'
          break
        case 11: // 临时任务
          // this.$store.commit('set_left_component', 'SetTempTask')
          this.curSetTaskComponent = 'SetTempTask'
          break
        default:
          // this.$store.commit('set_left_component', 'SetRepairTask')
          this.curSetTaskComponent = 'SetRepairTask'
          break
      }
    },
    handleAddNewTask () {
      if (this.taskType === 10) {
        // this.$store.commit('set_left_component', 'SetFillFile')
        this.curSetTaskComponent = 'SetFillFile'
      } else {
        // this.$store.commit('set_left_component', 'SetMaintainTask')
        this.curSetTaskComponent = 'SetMaintainTask'
      }
      this.setTaskModal = true
    },
    /**
     * 取消任务
     */
    handleCancelTask () {
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('Input', {
            props: {
              type: 'textarea',
              rows: 4,
              value: this.cancelRemark,
              autofocus: true,
              placeholder: '填写取消任务原因，非必填'
            },
            on: {
              input: (val) => {
                this.cancelRemark = val
              }
            }
          })
        },
        onOk: () => {
          applyRevokeTaskitem({ taskitemIds: JSON.stringify(this.selectedTaskitemIdArray), remark: this.cancelRemark }).then(res => {
            if (res && res.errcode === 0) {
              this.tempSelectedTaskItem = []
              this.selectedTaskitemIdArray = []
              this.selectedResourceIdArray = []
              this.getTaskitemData()
              this.$emit('handleSetSuccess')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 删除任务设置
     */
    handelDeleteTaskitem () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除所选数据？',
        onOk: () => {
          deleteTaskitem({ taskitemIds: JSON.stringify(this.selectedTaskitemIdArray) }).then(res => {
            if (res && res.errcode === 0) {
              this.tempSelectedTaskItem = []
              this.selectedTaskitemIdArray = []
              this.selectedResourceIdArray = []

              this.$Notice.success({ desc: '操作成功' })
              this.$emit('handleSetSuccess')
              this.getTaskitemData()
            }
          })
        }
      })
    },
    handleSubmitTask () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交已设置任务？',
        onOk: () => {
          submit({ taskId: this.query.taskId }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getTaskitemData()
              // 处理切换到首页
              this.$emit('handleSetSuccess')
            }
          })
        }
      })
    },
    handleSetSuccess () {
      this.setTaskModal = false
      this.selectedTroubleIds = []
      this.selectedResourceIdArray = []
      this.selectedFaultIdArray = []
      this.getTaskitemData()
    }
  },
  watch: {
    taskId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.curTaskId = val
          this.getCompanyAssetArray()
          this.getTaskitemData()
        }
      }
    }
  }
}
</script>
